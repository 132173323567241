import React from 'react';
import { HashRouter, Route, Routes } from "react-router-dom";
import { Layout } from "@/components/Layout";
import { routes } from './router';
import './App.less';
import 'lib-flexible';

function App() {
  return (
    <HashRouter>
      <Layout>
        <Routes>
        {routes.map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              // exact={item.exact}
              element={item.component}
            />
          );
        })}
      </Routes>
      </Layout>
    </HashRouter>
  );
}

export default App;
