import React from "react";
import styles from './style.module.less';
import './index.less'

export function Downloads() {

  return (
    <div className={`${styles.container} downloads_container`}>
      <div className={`${styles.banner} banner`}>
        <div className={styles.mask} />
        <div className={`${styles.banner_info} banner_info`}>
          <div className={styles.title}>您的生鲜生意好管家</div>
        </div>
      </div>
      <div className={`${styles.content_box} content_box`}>
        <div className={`${styles.center_box} center_box`}>
          <div className={`${styles.card_box} card_box`}>
            <img className={`${styles.img} img`} src={require('@/assets/images/downloads_img_1.png')} alt="" />
            <div className={`${styles.info_text_box} info_text_box`}>
              <div className={`${styles.info_title} info_title`}>鲜大师平板端</div>
              <div className={`${styles.info_text} info_text`}>支持Android/ios设备</div>
              <a className={styles.download_btn} target="_blank" href="https://sj.qq.com/appdetail/com.freshgurutablet" rel="noreferrer">下载</a>
            </div>
          </div>
          <div className={`${styles.card_box} card_box`}>
            <img className={`${styles.img} img`} src={require('@/assets/images/downloads_img_2.png')} alt="" />
            <div className={`${styles.info_text_box} info_text_box`}>
              <div className={`${styles.info_title} info_title`}>鲜大师手机端</div>
              <div className={`${styles.info_text} info_text`}>支持Android/ios设备</div>
              <a className={styles.download_btn} target="_blank" href="https://sj.qq.com/appdetail/com.freshgurumobile" rel="noreferrer">下载</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
