import { Home } from "@/pages/home";
import { Solution } from "@/pages/solution";
import { Introduce } from "@/pages/introduce";
import { Case } from "@/pages/case";
import { Support } from "@/pages/support";
import { Downloads } from "@/pages/downloads";

export const routes = [
  {
    path: "/",
    exact: true,
    component: <Home />,
  },
  {
    path: "/solution",
    exact: true,
    component: <Solution />,
  },
  {
    path: "/introduce",
    exact: true,
    component: <Introduce />,
  },
  {
    path: "/case",
    exact: true,
    component: <Case />,
  },
  {
    path: "/support",
    exact: true,
    component: <Support />,
  },
  {
    path: "/downloads",
    exact: true,
    component: <Downloads />,
  },
]
