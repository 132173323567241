import React, { useState } from "react";
import { Button, Dropdown, Space, Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import styles from './style.module.less';
import { menus } from './data';

const { Panel } = Collapse;

const platformUrl = process.env.REACT_APP_PLATFORM_URL;

console.log(process.env)

function HeaderPc() {
  const navigate = useNavigate();

  const onMenuClick = (path: string, key: string) => {
    navigate(path);

    document.querySelector(key)?.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    })
  }

  const onLinkTo = (path: string) => {
    navigate(path)
  }

  return (
    <div className={`${styles.header} header`}>
      <div className={styles.container}>
        <img className={styles.logo} src={require('@/assets/images/logo_a.png')} />
        <div className={styles.menu_box}>
          {menus.map(item => {
            if (item.items) {
              return (
                <Dropdown key={item.name} menu={{
                  items: item.items,
                  onClick: ({ key }) => onMenuClick(item.path, key)
                }}>
                  <Space>
                    {item.name}
                    {item.items && <DownOutlined />}
                  </Space>
                </Dropdown>
              )
            }

            return <a key={item.name} onClick={() => onLinkTo(item.path)} className={styles.menu_item}>{item.name}</a>
          })}
        </div>
        <Button target="_blank" href={`${platformUrl}/login`} className={styles.login} type="primary">平台登录</Button>
      </div>
    </div>
  )
}

function HeaderMobile() {
  const navigate = useNavigate();
  const [showMenuPanel, setShowMenuPanel] = useState<boolean>(false);

  const onSwitchMenuPanel = () => {
    setShowMenuPanel(!showMenuPanel)
  }

  const onMenuClick = (path: string, key: string) => {
    setShowMenuPanel(false);
    navigate(path);

    document.querySelector(key)?.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    })
  }

  const onLinkTo = (path: string) => {
    setShowMenuPanel(false);
    navigate(path);
  }

  return (
    <div className={`${styles.header_mobile} header_mobile`}>
      <img className={styles.logo} src={require('@/assets/images/logo_a.png')} />
      <img className={styles.icon} src={require('@/assets/images/home_icon_7.png')} onClick={onSwitchMenuPanel} />

      {showMenuPanel && (
        <div className={styles.menu_panel}>
         <Collapse accordion>
           {
             menus.map(item => {
               if (item.items) {
                 return (
                   <Panel key={item.name} header={item.name}>
                     {item.items.map(i =>
                       <div key={i.key} className={styles.menu_child_item} onClick={() => onMenuClick(item.path, i.key)}>{i.label}</div>)}
                   </Panel>
                 )
               }
               return <a key={item.name} className={styles.menu_item} onClick={() => onLinkTo(item.path)}>{item.name}</a>
             })
           }
          </Collapse>
        </div>
      )}
    </div>
  )
}

export function Header() {

  return (
    <>
      <HeaderPc />
      <HeaderMobile />
    </>
  )
}
