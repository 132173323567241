import React from "react";
import { Header } from "./header";
import { Footer } from "./footer";
import styles from './style.module.less';
import './index.less';

export function Layout(props: any) {
  return (
    <div className={styles.layout}>
      <Header />
      <div className={styles.container}>{props.children}</div>
      <Footer />
    </div>
  )
}
