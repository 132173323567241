import React, { useState, useEffect } from "react";
import { Button, Tooltip, Input, message } from 'antd';
import styles from './style.module.less';
import './index.less'
import { registerApi } from "@/pages/home/services";

export function Home() {
  const [phone, setPhone] = useState<string>('');
  const [showBackTop, setShowBackTop] = useState<boolean>(false)

  const backTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  };

  const onChange = (e: React.BaseSyntheticEvent) => {
    setPhone(e.target.value)
  }

  const handleTrial = async () => {
    if (!phone) return;

    const { success, message: msg } = await registerApi({ phone });

    if (success) {
      message.success('申请成功，稍后会有工作人员与您联系');
      setPhone('');
    } else {
      message.error(msg);
    }
  };

  const onToWechat = () => {
    window.location.href = 'https://work.weixin.qq.com/kfid/kfcd4b31f7e677e5356'
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrollTop = window.document.documentElement.scrollTop || window.document.body.scrollTop;
      setShowBackTop(scrollTop > 600)
    })

    return () => {
      window.removeEventListener('scroll', () => {
      })
    }
  }, []);

  return (
    <div className={`${styles.container} home_container`}>
      <div id="trial" className={`${styles.banner} banner`}>
        <div className={`${styles.center_box} center_box`}>
          <div className={`${styles.info_box} info_box`}>
            <div className={`${styles.title} title`}>鲜大师，您的生鲜生意好管家</div>
            <div className={`${styles.info_item} info_item`}>档口管理</div>
            <div className={`${styles.info_item} info_item`}>订单管理</div>
            <div className={`${styles.info_item} info_item`}>财务报表</div>
            <div className={`${styles.info_item} info_item`}>库存管理</div>
            <div className={`${styles.info_item} info_item`}>赊欠风控</div>
            <div className={`${styles.info_item} info_item`}>经营分析</div>
            <div className={`${styles.action} action`}>
              <Input value={phone} onChange={onChange} className={`${styles.input} input`} placeholder="请输入你的手机号码" />
              <Button className={`${styles.button} button`} onClick={handleTrial}>立即试用</Button>
            </div>
          </div>
          <img className={`${styles.img} banner_img`} src={require('@/assets/images/home_banner_img.png')} alt="" />
        </div>
      </div>
      <div id="company_profile" className={styles.company_profile}>
        <div className={`${styles.center_box} center_box`}>
          <div className={`${styles.title} company_profile_title`}>公司概况</div>
          <div className={`${styles.content} company_profile_content`}>
            <img className="company_profile_img" width={580} src={require('@/assets/images/home_img1.png')} alt="" />
            <div className={`${styles.info} company_profile_info`}>
              <div className={styles.info_title}>观妙科技（杭州）有限公司简介</div>
              <div className={`${styles.info_text} company_profile_info_text`}>
                <p>观妙科技（杭州）有限公司于2021年12月成立，创始人何崚（花名大少）为原阿里巴巴集团盒马CTO（P10），天猫首席架构师，阿里巴巴集团C-TEAM和组织部成员，主导了天猫、阿里数字供应链中台、盒马等多条核心产品链路由0到1的构建。创始团队均来自阿里巴巴与盒马等企业，在生鲜SaaS具有丰富的行业经验。</p>
                <p>观妙科技在2021年底完成天使轮，于2022年2月完成创始团队组建，目前团队60余人，产品技术占比80%。第一款产品“鲜大师”于2022年4月首发上市。立志于通过数据驱动打通生鲜行业上下游，为行业降本增效，与千万从业者共同创造更多可能。</p>
                <p>观妙科技希望用数字化手段帮助商家实现更高效发展。公司秉承“简单、开放、高效”的文化理念，怀着对行业的敬畏之心，将持续深入的学习，砥砺前行。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="introduction" className={styles.introduction}>
        <div className={`${styles.center_box} center_box`}>
          <div className={`${styles.title} introduction_title`}>系统简介</div>
          <div className={`${styles.des} introduction_des`}>通过数字化技术帮助农批商家快速成长</div>
          <div className={`${styles.card_list} card_list`}>
            <div className={`${styles.card} card`}>
              <img className="card_img" width={174} src={require('@/assets/images/home_card_1.png')} />
              <div className={styles.card_info}>
                货主批次、货品和客户<br />
                下单清晰明了，过程便捷高效
              </div>
            </div>
            <div className={`${styles.card} card`}>
              <img className="card_img" width={174} src={require('@/assets/images/home_card_2.png')} />
              <div className={styles.card_info}>
                买家实名认证，逾期风险设定<br />
                提高风险把控，杜绝跑账
              </div>
            </div>
            <div className={`${styles.card} card`}>
              <img className="card_img" width={174} src={require('@/assets/images/home_card_3.png')} />
              <div className={styles.card_info}>
                账单一键分享<br />
                有效触达买家，提升回款效率
              </div>
            </div>
            <div className={`${styles.card} card`}>
              <img className="card_img" width={174} src={require('@/assets/images/home_card_4.png')} />
              <div className={styles.card_info}>
                批次入库，批次售卖，盘点报损<br />
                彻底解决库存不清晰
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="analysis" className={styles.analysis}>
        <div className={`${styles.center_box} center_box`}>
          <div className={`${styles.title} analysis_title`}>14个省12000家客户正在使用鲜大师</div>
          <div className={`${styles.item_list} analysis_item_list`}>
            <div className={`${styles.item} analysis_item`}>
              <div className={`${styles.item_title} analysis_item_title`}>1v1专属</div>
              <div className={styles.item_des}>1v1专属运营团队</div>
            </div>
            <div className={`${styles.item} analysis_item`}>
              <div className={`${styles.item_title} analysis_item_title`}>100+团队</div>
              <div className={styles.item_des}>100+团队随处在</div>
            </div>
            <div className={`${styles.item} analysis_item`}>
              <div className={`${styles.item_title} analysis_item_title`}>7*24小时</div>
              <div className={styles.item_des}>7*24小时全程跟踪</div>
            </div>
            <div className={`${styles.item} analysis_item`}>
              <div className={`${styles.item_title} analysis_item_title`}>14个省</div>
              <div className={styles.item_des}>全国14个省覆盖</div>
            </div>
          </div>
        </div>
      </div>
      <div id="partner" className={styles.partner}>
        <div className={`${styles.center_box} center_box`}>
          <div className={`${styles.title} partner_title`}>战略合作伙伴</div>
          <img className={`${styles.img} partner_img`} src={require('@/assets/images/home_img2.png')} alt="" />
          <img className={`${styles.img} partner_img`} src={require('@/assets/images/home_img3.png')} alt="" />
          <img className={`${styles.img} partner_img`} src={require('@/assets/images/home_img4.png')} alt="" />
          <img className={`${styles.img} partner_img`} src={require('@/assets/images/home_img5.png')} alt="" />
          <img className={`${styles.img} partner_img`} src={require('@/assets/images/home_img6.png')} alt="" />
        </div>
      </div>
      <div className={`${styles.affix} affix`}>
        <Tooltip placement="left" title={
          <>
            <img width={32} src={require('@/assets/images/home_icon_1.png')} />
            <span className="tip_line" />
            <span className="tip_text">400-6880861</span>
          </>
        }>
          <img src={require('@/assets/images/home_icon_2.png')} className={styles.tip_icon} />
        </Tooltip>
        <Tooltip className="affix_wechat_pc" placement="left" title={
          <div className={styles.wechat_qrcode_box}>
            <img src={require('@/assets/images/home_wechat_qrcode.png')} className={styles.wechat_qrcode} />
            <span className={styles.tip_text}>微信扫一扫</span>
            <span className={styles.tip_text}>免费申请使用</span>
          </div>
        }>
          <img onClick={() => console.log('123')} src={require('@/assets/images/home_icon_3.png')} className={styles.tip_icon} />
        </Tooltip>
        <img onClick={onToWechat} src={require('@/assets/images/home_icon_3.png')} className={`${styles.tip_icon} affix_wechat_mobile`} />
        {showBackTop && (
          <img
            src={require('@/assets/images/home_icon_4.png')}
            className={styles.tip_icon}
            onClick={backTop}
          />
        )}
      </div>
    </div>
  )
}
