import React, { useState } from "react";
import styles from './style.module.less';
import './index.less';
import { postInfo } from './data';
import icon1 from '@/assets/images/support_icon_1.png';
import icon2 from '@/assets/images/support_icon_2.png';

export function Support() {
  const [postList, setPostList] = useState(postInfo);

  const onAction = (data: any) => {
    setPostList((states) => states.map(item => {
      return { ...item, isOpen: item.key === data.key ? !item.isOpen : item.isOpen }
    }))
  }

  return (
    <div className={`${styles.container} support_container`}>
      <div className={`${styles.banner} banner`}>
        <div className={styles.mask} />
        <div className={`${styles.banner_info} banner_info`}>
          <div className={`${styles.des} des`}>观妙科技天使轮估值3.3亿，于2022年2月完成创始团队组建，目前团队60余人，产品技术占比80%。第一款产品“鲜大师”于2022年4月首发上市。</div>
        </div>
      </div>
      <div id="support" className={`${styles.support_box} support_box`}>
        <div className={`${styles.center_box} center_box`}>
          <div className={styles.title}>技术支持</div>
          <div className={`${styles.content} content`}>
            <img src={require('@/assets/images/support_img_1.png')} alt="" className={`${styles.img} support_box_img`} />
            <div className={`${styles.info} info`}>
              <a target="_blank" href="https://www.yuque.com/chengxianhui/qgqfw5?#" className={`${styles.info_item} info_item`}>
                <img src={require('@/assets/images/support_arrow.png')} className={styles.arrow} />
                产品功能介绍
              </a>
              <a target="_blank" href="https://www.yuque.com/chengxianhui/qgqfw5?#" className={`${styles.info_item} info_item`}>
                <img src={require('@/assets/images/support_arrow.png')} className={styles.arrow} />
                售后宝典
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="recruitment" className={`${styles.recruitment} recruitment`}>
        <div className={`${styles.center_box} center_box`}>
          <div className={styles.title}>人才招聘</div>
          <div className={`${styles.introduce} introduce`}>
            <div className={styles.item}>福利待遇： 弹性工作，不打卡，偶尔加班，双休，入职交五险一金，享受法律规定节假日，不定期部门团建，入职次年福利体检；</div>
            <div className={styles.item}>面试流程：投递简历至duanqianqian@gram-tech.com，简历通过筛选，HR沟通安排第一轮业务部门面试；第一轮面试通过后，根据岗位及业务部门内部流程安排第二或第三轮面试，终面通过后沟通offer再确认入职。</div>
          </div>
          {postList.map(item => (
            <div key={item.key} className={`${styles.folding_panel} folding_panel`}>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.des}>{item.des}</div>
              <div className={`${styles.info} ${item.isOpen && styles.open}`}>{item.info}</div>
              <div className={styles.action} onClick={() => onAction(item)}>
                {!item.isOpen ? '展开更多' : '收起内容'}
                <img src={!item.isOpen ? icon1 : icon2} alt="" className={styles.icon} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={`${styles.qrcodes} qrcodes`}>
        <div className={`${styles.item} item`}>
          <img src={require('@/assets/images/support_img_2.png')} alt="" className={styles.qrcode} />
          扫一扫 添加企业微信
        </div>
        <div className={`${styles.item} item`}>
          <img src={require('@/assets/images/support_img_3.png')} alt="" className={styles.qrcode} />
          扫一扫 关注公众号
        </div>
      </div>
    </div>
  )
}
