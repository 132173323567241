// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/downloads_banner_m.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 768px) {\n  .downloads_container .banner {\n    height: 10.741rem;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  }\n  .downloads_container .content_box {\n    padding: 0.926rem;\n  }\n  .downloads_container .content_box .center_box {\n    width: 100%;\n    flex-direction: column;\n  }\n  .downloads_container .content_box .center_box .card_box {\n    width: 100%;\n    margin-bottom: 0.741rem;\n  }\n  .downloads_container .content_box .center_box .card_box:last-child {\n    margin-bottom: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/downloads/index.less"],"names":[],"mappings":"AAAA;EACE;IAEI,iBAAA;IACA,yDAAA;EAAJ;EAHA;IAUI,iBAAA;EAJJ;EANA;IAaM,WAAA;IACA,sBAAA;EAJN;EAVA;IAiBQ,WAAA;IACA,uBAAA;EAJR;EAMQ;IACE,gBAAA;EAJV;AACF","sourcesContent":["@media (max-width: 768px) {\n  .downloads_container {\n    .banner {\n      height: 10.741rem;\n      background-image: url(\"@/assets/images/downloads_banner_m.png\");\n\n      .title {\n      }\n    }\n\n    .content_box {\n      padding: 0.926rem;\n\n      .center_box {\n        width: 100%;\n        flex-direction: column;\n\n        .card_box {\n          width: 100%;\n          margin-bottom: 0.741rem;\n\n          &:last-child {\n            margin-bottom: 0;\n          }\n        }\n      }\n    }\n  }\n}\n\n@primary-color: #21C9B8;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
