import React from "react";
import { Tabs } from 'antd';
import styles from './style.module.less';
import './index.less'

export function Case() {

  return (
    <div className={`${styles.container} case_container`}>
      <div className={`${styles.banner} banner`}>
        <div className={styles.mask} />
        <div className={`${styles.banner_info} banner_info`}>
          <div className={styles.title}>农批SaaS——多角色协同、软硬件结合的一站式解决方案</div>
          <div className={`${styles.des} des`}>我们的使命  通过数字化手段帮助农批经营降本增收</div>
          <div className={`${styles.des} des`}>我们的愿景  希望成为全球农批商家信赖的经营外脑</div>
        </div>
      </div>
      <div className={`${styles.content_box} content_box`}>
        <div className={`${styles.center_box} center_box`}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="文章专访" key="1">
              <div className={`${styles.card_list} card_list`}>
                <div className={`${styles.card} card`}>
                  <img className={`${styles.img} img`} src={require('@/assets/images/case_img_1.png')} alt="" />
                  <div className={`${styles.title} title`}>老程姜蒜直销</div>
                  <div className={`${styles.info} info`}>解决了每天都要盯在档口，不能非现场管理效率低。账目管理只能依赖财务，跑账风险大的问题。所有账目在鲜大师上都有记录，清晰透明，大大方便了平时的工作。</div>
                </div>
                <div className={`${styles.card} card`}>
                  <img className={`${styles.img} img`} src={require('@/assets/images/case_img_2.png')} alt="" />
                  <div className={`${styles.title} title`}>刘跃根蔬菜批发</div>
                  <div className={`${styles.info} info`}>以前销量和库存不能实时把控，货品丢失风险高，开单错单漏单高，账本丢失风险大，货品数据统计时间长。现在，鲜大师上随时能看到实时报表，销量和库存随查随看，线上“账本”尽在掌握。</div>
                </div>
                <div className={`${styles.card} card`}>
                  <img className={`${styles.img} img`} src={require('@/assets/images/case_img_3.png')} alt="" />
                  <div className={`${styles.title} title`}>云生蔬菜批发</div>
                  <div className={`${styles.info} info`}>商行与货主间销售价格不透明、交易信息对接效率低是一个很大的问题。用了鲜大师之后各类商品价格信息都能轻松查到，少走很多弯路，方便了交易。</div>
                </div>
                <div className={`${styles.card} card`}>
                  <img className={`${styles.img} img`} src={require('@/assets/images/case_img_4.png')} alt="" />
                  <div className={`${styles.title} title`}>张福生蔬菜批发</div>
                  <div className={`${styles.info} info`}>每天账目手动统计时间长，且出错率高，账目处理上浪费大量时间。在鲜大师上所有账目智能计算再也不用手工一单一单算，大大提高了对账的效率方便了工作。</div>
                </div>
              </div>
            </Tabs.TabPane>
            {/*<Tabs.TabPane tab="视频专访" key="2"></Tabs.TabPane>*/}
          </Tabs>
        </div>
      </div>
    </div>
  )
}
