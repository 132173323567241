import React from "react";
import styles from './style.module.less';
import './index.less'

export function Solution() {

  return (
    <div className={`${styles.container} solution_container`}>
      <div className={`${styles.banner} banner`}>
        <div className={styles.mask} />
        <div className={`${styles.banner_info} banner_info`}>
          <div className={styles.title}>数字化手段帮助农批企业高速成长</div>
          <div className={`${styles.des} des`}>提供一站式农批市场的数字化解决方案，利用移动端互联网、云计算、人工智能赋能行业，让您轻松管理批发档口各供货商、客户、货主、自营、代销、库存、结算、财务等，同时帮助企业打通内外部、线上线下各个流程业务，高效管理批发运营和销售。</div>
        </div>
      </div>
      <div className={`${styles.content_box} content_box`}>
        <div className={`${styles.center_box} center_box`}>
          <div className={`${styles.title} title`}>订单管理难，库存管理难？</div>
          <div className={`${styles.info_box} info_box`}>
            <img className={`${styles.img} img`} src={require('@/assets/images/solution_img_1.png')} alt="" />
            <div className={`${styles.info_text_box} info_text_box`}>
              <div className={`${styles.info_item_text} info_item_text`}>1、便捷高效的开单功能，提升档口的收货，下单和收银的效率；</div>
              <div className={`${styles.info_item_text} info_item_text`}>2、小程序买家互动提升订单的确定性准确性；</div>
              <div className={`${styles.info_item_text} info_item_text`}>3、关联单据的自动高效的出入库管理，盘点，报损能力，提高库存的准确性。</div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.content_box} content_box`}>
        <div className={`${styles.center_box} center_box`}>
          <div className={`${styles.title} title`}>赊欠管理难？</div>
          <div className={`${styles.info_box} info_box`}>
            <div className={`${styles.info_text_box} info_text_box`}>
              <div className={`${styles.info_item_text} info_item_text`}>1、实名认证防欺诈，确保您的赊欠资金更安全；</div>
              <div className={`${styles.info_item_text} info_item_text`}>2、帐期提醒控风险，赊账管理千人千面，实用又人性；</div>
              <div className={`${styles.info_item_text} info_item_text`}>3、账单一键长图分享，更有效触达买家，提升回款效率。</div>
            </div>
            <img className={`${styles.img} img`} src={require('@/assets/images/solution_img_2.png')} alt="" />
          </div>
        </div>
      </div>
      <div className={`${styles.content_box} content_box`}>
        <div className={`${styles.center_box} center_box`}>
          <div className={`${styles.title} title`}>查账对账效率低？</div>
          <div className={`${styles.info_box} info_box`}>
            <img className={`${styles.img} img`} src={require('@/assets/images/solution_img_3.png')} alt="" />
            <div className={`${styles.info_text_box} info_text_box`}>
              <div className={`${styles.info_item_text} info_item_text`}>1、支持多端的对账能力，货主对账，供应商对账，客户对账，省时省力，随时随地可对账可结算。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
