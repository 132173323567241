/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';

export interface AxiosRequest extends AxiosRequestConfig {
  loading?: boolean;
}

export interface RequestResponse<T> {
  data: T;
  message: string;
  success: boolean;
  status: number;
}

let instance: AxiosInstance | undefined;

export function resetInstance() {
  instance = undefined;
}

const baseUrl = process.env.REACT_APP_BASE_URL;

function initInstance() {
  // 创建axios实例
  instance = axios.create({
    baseURL: baseUrl,
    timeout: 10000,
    headers: {},
  });

  // 添加请求拦截器
  instance.interceptors.request.use(
    (config: AxiosRequest) => {
      return config;
    },
    error => {

      return Promise.reject(error);
    },
  );

  // 添加响应拦截器
  // @ts-ignore
  instance.interceptors.response.use((response: AxiosResponse) => {
      const { status, msg, data } = response.data;
      if (status === 0) {
        return {
          success: true,
          message: msg,
          data,
        };
      }

      return { success: false, message: msg, data };
    },
    async error => {
      return Promise.reject(error);
    },
  );

  return instance;
}

// request
export const request = async <T>(options: AxiosRequest): Promise<RequestResponse<T>> => {
  const { method = 'post', params, headers, ...reset } = options;
  const key = method.toUpperCase() === 'GET' ? 'params' : 'data';
  if (!instance) {
    instance = initInstance();
  }
  return instance.request({
    method,
    [key]: params,
    ...reset,
  });
};
