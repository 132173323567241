import { request } from "@/utils/request";
import { Register } from "../services/types";

export async function registerApi(params: Register.Request): Promise<Register.Response> {
  const { success, message } = await request<Register.Response>({
    url: 'index/saveInfo',
    params,
  })

  return { success, message };
}
