export const postInfo = [
  {
    key: '1',
    isOpen: false,
    title: '高级web前端开发工程师',
    des: '薪酬：15k-30k',
    info: <>
            岗位职责：<br />
            1、负责 SaaS 业务平台前端的开发，包括中后台，跨端以及工程化等方向；<br />
            2、负责业务线的技术储备及规划，核心技术问题的攻关等；<br />
            岗位要求：<br />
            1、全日制本科及以上，3年以上同岗位工作经验；<br />
            2、熟悉 React、React Hook、TS 及其生态圈；<br />
            3、包括但不限于后端、客户端、中后台、数据可视化等领域的实践经验及思考沉淀；<br />
            4、热衷技术、追究原理、探究最佳实践；<br />
            5、具备良好的沟通能力和优秀的团队协作精神；<br />
            6、具备良好的工程化、组件化思维及相关经验；<br />
            7、主动性、抗压能力强，具备业务线合伙人特质，推动业务发展；<br />
            加分项：<br />
            1、接触过后端，从后到前的相关技术有一定的自我思考；<br />
            2、乐于总结与分享，附上自己技术博客链接；<br />
            3、积极参与开源项目并贡献过代码，附上 Github 链接；<br />
         </>
  },
  {
    key: '2',
    isOpen: false,
    title: '资深Java开发工程师',
    des: '薪酬：20k-30k',
    info: <>
            岗位职责:<br />
            1、参与需求分析、系统架构设计、撰写相关文档，编写代码实现产品功能，完成项目开发。<br />
            2、协助进行核心技术问题的攻关，技术方案的选型、系统优化，协助解决项目开发过程中的技术难题;<br />

            岗位要求:<br />
            1、5年以上Java开发经验，有项目管理能力；<br />
            2、熟练运用Spring，MyBatis，redis等框架进行开发工作；<br />
            3、掌握java多线程编程，socket编程，有一定的性能调优经验；<br />
            4、有带过10人左右的团队项目经验’<br />
            5、熟悉常用的设计模式，分布式应用架构设计<br />
            6、良好的抽象设计能力，能独立解决问题；<br />
            7、有良好团队合作精神，较强的责任心和抗压能力；<br />
            8、有良好的跨团队协调沟通能力；<br />
            9、有零售ERP，WMS等相关开发经验优先；<br />
          </>
  },
  {
    key: '3',
    isOpen: false,
    title: '产品经理',
    des: '薪酬：15k-25k',
    info: <>
            岗位职责：<br />
            1、负责系统整体架构规划和设计，从规划层，管理层，执行层到监控层整体功能框架，并协助整个平台的搭建和实施；<br />
            2、能快速理解业务需求，竞品分析，分析行业动态，制定产品策略，对产品进行迭代和优化完善；<br />
            3、负责业务方需求的挖掘，能够快速理解业务场景，抽象需求输出产品方案，解决用户使用痛点；<br />
            岗位要求：<br />
            1、3年以上相关产品包括进销存的设计经验，熟悉B2B、零售门店供应链产品设计；<br />
            2、熟悉ERP、采购管理、调拨管理、合同管理、供应商管理、自动补货等系统的相关流程及操作流程；<br />
            3、能够清晰的撰写需求文档和产品原型，具备产品的需求分析和规划能力，思维敏捷，逻辑清晰，具备较强的分析问题和解决问题能力；<br />
            4、撰写详细的产品需求文档及原型设计文档，跟踪产品研发进度；<br />
            5、较强的沟通协调能力，完成跨部门、跨职能沟通、有强烈的责任心和团队合作精神、以及自我驱动力；<br />
            6、从事过生鲜行业的优先考虑。<br />
            福利待遇： 弹性工作，不打卡，偶尔加班，双休，入职交五险一金，享受法律规定节假日，不定期部门团建，入职次年福利体检；<br />
            面试流程：投递简历至duanqianqian@gram-tech.com，简历通过筛选，HR沟通安排第一轮业务部门面试；第一轮面试通过后，根据岗位及业务部门内部流程安排第二或第三轮面试，终面通过后沟通offer再确认入职。<br />
          </>
  },
  {
    key: '4',
    isOpen: false,
    title: '销售BD BDM CM',
    des: '薪酬：10k-20k',
    info: <>
            职位详情<br />
            对企业/机构（To B）销售经验 退伍军人<br />
            企业(To B) 互联网/科技 批发/零售<br />
            软件/企业服务产品 面销/陌拜<br />
            岗位描述：<br />
            从事生鲜行业ERP软件服务销售，为生鲜批发商户提供经营管理解决方案。以地推陌拜的方式开展工作。挖掘客户需求，根据客户需求匹配全流程解决方案，传递公司产品价值。<br />
            岗位职责：<br />
            1、负责完成公司分配责任区域内的销售任务。<br />
            2、负责区域内市场数据收集和客户分析。<br />
            3、负责所在辖区域客户需求收集和反馈。<br />
            4、负责区域内合作客户客情关系维护。<br />
            任职要求：<br />
            1、有较强的沟通能力和团队协作能力。<br />
            2、有较强的抗压能力，吃苦耐劳，敢于挑战。<br />
            3、一年以上的销售工作经历，有ToB前置付费业务经验者和生鲜从业经验者优先。<br />
            4、能服从公司安排省内出差或异地驻点。<br />
          </>
  },
];
