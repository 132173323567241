import React from "react";
import styles from './style.module.less';

export function Footer() {

  return (
    <div className={`${styles.footer} footer`}>
      <div className={`${styles.container} footer_container`}>
        <div className={`${styles.left} footer_left`}>
          <img className={`${styles.logo} footer_logo`} src={require('@/assets/images/logo_b.png')} alt="" />
          <div className={styles.info}>
            <div className={styles.item}>客服电话：400-688-0861</div>
            <div className={styles.item}>公司名称：观妙科技（杭州）有限公司</div>
            <div className={styles.item}>公司地址：杭州市余杭区文一西路二期人工智能小镇3幢606室</div>
            <a href="http://beian.miit.gov.cn/" target="_blank" className={styles.item} rel="noreferrer">浙ICP备2022002090号</a>
          </div>
        </div>
        <div className={`${styles.right} footer_right`}>
          <div className={`${styles.tips} footer_tips`}>
            鲜大师客服微信<br />
            扫码咨询更多详情
          </div>
          <img className={styles.qrcode} src={require('@/assets/images/qrcode.png')} alt="" />
        </div>
      </div>
    </div>
  )
}
