import React from "react";
import styles from './style.module.less';
import './index.less'

export function Introduce() {

  return (
    <div className={`${styles.container} introduce_container`}>
      <div className={`${styles.banner} banner`}>
        <div className={styles.title}>系统版本和功能介绍</div>
        <img className={`${styles.banner_img} banner_img`} src={require('@/assets/images/introduce_img_1.png')} alt="" />
      </div>
      <div className={`${styles.content_box} content_box`}>
        <div className={`${styles.center_box} center_box`}>
          <div className={`${styles.card_box} card_box`}>
            <div className={styles.mask} />
            <div className={`${styles.info_box} info_box`}>
              <div className={`${styles.title} title`}>鲜大师平板端</div>
              <div className={`${styles.info} info`}>开单收银，订单管理，库存管理，批次结算，财务对账，报表管理等，商行日常经营流程更高效。</div>
            </div>
          </div>
          <div className={`${styles.card_box} card_box`}>
            <div className={styles.mask} />
            <div className={`${styles.info_box} info_box`}>
              <div className={`${styles.title} title`}>鲜大师手机端</div>
              <div className={`${styles.info} info`}>开单收银，账单分享，远程还款，货主对账等远程管理。解放经营者的时间和空间。</div>
            </div>
          </div>
          <div className={`${styles.card_box} card_box`}>
            <div className={styles.mask} />
            <div className={`${styles.info_box} info_box`}>
              <div className={`${styles.title} title`}>微信小程序</div>
              <div className={`${styles.info} info`}>客户采购对账，账单确认，销售明细表等。各平台信息互通沟通更高效。</div>
            </div>
          </div>
          <div className={`${styles.card_box} card_box`}>
            <div className={styles.mask} />
            <div className={`${styles.info_box} info_box`}>
              <div className={`${styles.title} title`}>鲜大师电脑端</div>
              <div className={`${styles.info} info`}>营报表查看，经营数据自定义导出，客户管理，商行管理等。财务报表更多元化。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
