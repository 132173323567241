export const menus = [
  {
    name: '首页',
    path: '/',
    items: [
      {
        key: '#company_profile',
        label: '公司概况',
      },
      {
        key: '#introduction',
        label: '系统简介',
      },
      {
        key: '#analysis',
        label: '数据分析',
      },
      {
        key: '#partner',
        label: '战略合作伙伴',
      },
    ]
  },
  {
    name: '解决方案',
    path: '/solution',
  },
  {
    name: '系统介绍',
    path: '/introduce',
  },
  {
    name: '客户案例',
    path: '/case',
  },
  {
    name: '技术支持',
    path: '/support',
    items: [
      {
        key: '#support',
        label: '技术支持',
      },
      {
        key: '#recruitment',
        label: '人才招聘',
      },
    ]
  },
  {
    name: '下载中心',
    path: '/downloads',
  },
]
